import {createMuiTheme} from '@material-ui/core';
import {lightBlue, red} from '@material-ui/core/colors';
import {fuseDark} from '@fuse/fuse-colors';
import _ from '@lodash';
import qs from 'qs';
import { buttonBgColor, buttonBgHoverColor, buttonBgSecondaryColor, buttonDisabledBgColor, buttonTextColor, buttonTextHoverColor, buttonTextSecondaryColor } from 'styles/BasicStyles';

/**
 * SETTINGS DEFAULTS
 */
export const defaultSettings = {
    customScrollbars: true,
    theme           : {
        main   : 'default',
        navbar : 'mainThemeDark',
        toolbar: 'mainThemeLight',
        footer : 'mainThemeDark'
    }
};

export function getParsedQuerySettings()
{
    const parsedQueryString = qs.parse(window.location.search, {ignoreQueryPrefix: true});

    if ( parsedQueryString && parsedQueryString.defaultSettings )
    {
        return JSON.parse(parsedQueryString.defaultSettings);
    }
    return {}

    // Generating route params from settings
    /*const settings = qs.stringify({
        defaultSettings: JSON.stringify(defaultSettings, {strictNullHandling: true})
    });
    console.info(settings);*/
}

/**
 * THEME DEFAULTS
 */
export const defaultThemeOptions = {
    typography: {
        fontFamily                 : [
            'Futura PT',
            'Muli',
            'Roboto',
            '"Helvetica"',
            'Arial',
            'sans-serif'
        ].join(','),
        fontWeightLight            : 300,
        fontWeightRegular          : 400,
        fontWeightMedium           : 600,
        useNextVariants            : true,
        suppressDeprecationWarnings: true
    },
    overrides: {
        MuiButton: {
            contained: {
                boxShadow: 'none',
                '&:hover': {
                    boxShadow: 'none',
                },
            },
            containedPrimary: {
                backgroundColor: buttonBgColor,
                color: buttonTextColor,
                '&:hover': {
                    boxShadow: 'none',
                    color: buttonTextHoverColor,
                    backgroundColor: buttonBgHoverColor
                },
                '&$disabled': {
                    color: '#000',
                    backgroundColor: buttonDisabledBgColor,
                    '&:hover': {
                        color: buttonTextHoverColor,
                        backgroundColor: buttonBgHoverColor,
                    },
                }
            },
            containedSecondary: {
                backgroundColor: buttonBgSecondaryColor,
                color: buttonTextSecondaryColor,
                '&:hover': {
                    boxShadow: 'none',
                    color: buttonTextHoverColor,
                    backgroundColor: buttonBgHoverColor
                },
                '&$disabled': {
                    color: '#000',
                    backgroundColor: buttonDisabledBgColor,
                    '&:hover': {
                        color: buttonTextHoverColor,
                        backgroundColor: buttonBgHoverColor,
                    },
                }
            },
            outlined: {
                boxShadow: 'none',
                '&:hover': {
                    boxShadow: 'none',
                },
            },
            outlinedPrimary: {
                '&:hover': {
                    boxShadow: 'none',
                    color: buttonTextHoverColor,
                    backgroundColor: buttonBgHoverColor
                },
                '&$disabled': {
                    color: '#000',
                    backgroundColor: buttonDisabledBgColor,
                    '&:hover': {
                        color: buttonTextHoverColor,
                        backgroundColor: buttonBgHoverColor,
                    },
                }
            }
        },
        MuiFab: {
            root: {
                boxShadow: 'none',
            },
            primary: {
                color: buttonTextColor,
                backgroundColor: buttonBgColor,
                '&:hover': {
                    color: buttonTextHoverColor,
                    backgroundColor: buttonBgHoverColor,
                },
                '&$disabled': {
                    color: '#000',
                    backgroundColor: buttonDisabledBgColor,
                    '&:hover': {
                        color: buttonTextHoverColor,
                        backgroundColor: buttonBgHoverColor,
                    },
                }
            },
            secondary: {
                color: buttonTextSecondaryColor,
                backgroundColor: buttonBgSecondaryColor,
                '&:hover': {
                    color: buttonTextHoverColor,
                    backgroundColor: buttonBgHoverColor,
                },
                '&$disabled': {
                    color: '#000',
                    backgroundColor: buttonDisabledBgColor,
                    '&:hover': {
                        color: buttonTextHoverColor,
                        backgroundColor: buttonBgHoverColor,
                    },
                }
            },
        },
        MuiDialog: {
            paper: {
                boxShadow: 'none',
            }
        },
        MuiAppBar: {
            colorPrimary: {
                color: '#000',
                backgroundColor: '#fff',
            }
        },
        MuiCheckbox: {
            colorSecondary: {
                color: '#000',
                '&$checked': {
                    color: '#000',
                }
            },
        },
        MuiBadge: {
            colorPrimary: {
                backgroundColor: '#000',
                color: '#fff'
            }
        },
        MuiMenuItem: {
            root: {
                '&:hover': {
                    color: buttonTextHoverColor,
                    backgroundColor: buttonBgHoverColor,
                },
                '&$selected': {
                    color: buttonTextColor,
                    backgroundColor: buttonBgColor,
                    '&:hover': {
                        color: buttonTextHoverColor,
                        backgroundColor: buttonBgHoverColor,
                    },
                }
            }
        },
        MuiAutocomplete: {
            option: {
                '&:hover': {
                    color: buttonTextHoverColor,
                    backgroundColor: buttonBgHoverColor,
                },
                '&$selected': {
                    color: buttonTextColor,
                    backgroundColor: buttonBgColor,
                    '&:hover': {
                        color: buttonTextHoverColor,
                        backgroundColor: buttonBgHoverColor,
                    },
                }
            }
        }
    }
};

export const mustHaveThemeOptions = {
    typography: {
        htmlFontSize: 10,
        body1       : {
            fontSize: "1.4rem",
        },
        body2       : {
            fontSize: "1.4rem",
        }
    }
};

export const defaultThemes = {
    default    : {
        palette: {
            type     : 'light',
            primary  : fuseDark,
            secondary: {
                light: lightBlue[400],
                main : lightBlue[600],
                dark : lightBlue[700]
            },
            error    : red
        },
        status : {
            danger: 'orange'
        }
    },
    defaultDark: {
        palette: {
            type     : 'dark',
            primary  : fuseDark,
            secondary: {
                light: lightBlue[400],
                main : lightBlue[600],
                dark : lightBlue[700]
            },
            error    : red
        },
        status : {
            danger: 'orange'
        }
    }
};

export function extendThemeWithMixins(obj)
{
    const theme = createMuiTheme(obj);
    return {
        border      : (width = 1) => ({
            borderWidth: width,
            borderStyle: 'solid',
            borderColor: theme.palette.divider
        }),
        borderLeft  : (width = 1) => ({
            borderLeftWidth: width,
            borderStyle    : 'solid',
            borderColor    : theme.palette.divider
        }),
        borderRight : (width = 1) => ({
            borderRightWidth: width,
            borderStyle     : 'solid',
            borderColor     : theme.palette.divider
        }),
        borderTop   : (width = 1) => ({
            borderTopWidth: width,
            borderStyle   : 'solid',
            borderColor   : theme.palette.divider
        }),
        borderBottom: (width = 1) => ({
            borderBottomWidth: width,
            borderStyle      : 'solid',
            borderColor      : theme.palette.divider
        })
    }
}

export function mainThemeVariations(theme)
{
    return {
        mainThemeDark : createMuiTheme(_.merge({}, defaultThemeOptions, theme, {palette: {type: 'dark'}, ...mustHaveThemeOptions})),
        mainThemeLight: createMuiTheme(_.merge({}, defaultThemeOptions, theme, {palette: {type: 'light'}, ...mustHaveThemeOptions}))
    }
}
