export const appBaseColor = '#00adee'
export const lightBlueColor = '#DFECF3'
export const assignmentSubtitleColor = 'rgba(0, 0, 0, 0.54)'
export const deleteColor = 'red'
export const lessonUnpublishedColor = '#FF6600'

export const buttonBgColor = '#000000'
export const buttonTextColor = '#ffffff'

export const buttonBgSecondaryColor = 'lightgrey'
export const buttonTextSecondaryColor = '#000000'

export const buttonBgHoverColor = '#00adee'
export const buttonTextHoverColor = '#ffffff'

export const buttonDisabledBgColor = 'rgba(0, 0, 0, 0.12)'

export const bubbleButtonStyle = Object.freeze({
    width: '100%',
    backgroundColor: buttonBgColor,
    color: buttonTextColor,
    height: 80,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    boxShadow: 'none',
    cursor: 'pointer',
    borderRadius: '0.8rem',
    margin: '1.25rem',
    maxWidth: '40rem',
    '&:hover': {
        color: buttonTextHoverColor,
        backgroundColor: buttonBgHoverColor,
    },
})