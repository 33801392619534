import React, { useState } from 'react'
import { Button, ListItemIcon, ListItemText, Popover, MenuItem } from '@material-ui/core'
import { texti18n } from 'dpn-common/utils/MessageConstants'

function DropDownButtonComponent({ button, renderButton, items, menuId, atElement, ...otherProps }) {
    const [menu, setMenu] = useState(null)

    const onMenuClick = event => setMenu(event.currentTarget)
    const onMenuClose = () => setMenu(null)

    return (
        <React.Fragment>
            {!!button && 
                <Button aria-describedby={menuId} aria-haspopup="true" onClick={onMenuClick} {...otherProps}>
                    {button}
                </Button>
            }
            {!!renderButton && renderButton(onMenuClick)}
            <Popover
                id={menuId}
                open={Boolean(menu)}
                anchorEl={menu}
                onClose={onMenuClose}
                anchorOrigin={atElement ? anchorOriginAtElement : anchorOriginDefault}
                transformOrigin={atElement ? transformOriginAtElement : transformOriginDefault}
                classes={{
                    paper: 'py-8'
                }}
            >
                {
                    items && items.map(({ title, action, icon }, index) => (
                        <MenuItem className="mui-button-icon" key={index} onClick={() => {
                            onMenuClose()
                            action()
                        }}>
                            {
                                !!icon && 
                                <ListItemIcon className="min-w-40 icon">
                                    {icon}
                                </ListItemIcon>
                            }
                            <ListItemText className="pl-0" primary={texti18n(title)} />
                        </MenuItem>
                    ))
                }
            </Popover>
        </React.Fragment>
    )
}

export default React.memo(DropDownButtonComponent)

const anchorOriginDefault = {
    vertical: 'bottom',
    horizontal: 'center'
}

const transformOriginDefault = {
    vertical: 'top',
    horizontal: 'center'
}

const anchorOriginAtElement = {
    vertical: 'top',
    horizontal: 'left'
}

const transformOriginAtElement = {
    vertical: 'top',
    horizontal: 'left'
}
