import React from 'react'
import { Messages, texti18n } from 'dpn-common/utils/MessageConstants'
import {
    Dialog, AppBar, Toolbar, Typography, DialogContent, Checkbox, FormControlLabel, DialogActions, Button
} from '@material-ui/core'
import ImageComponent from 'components/General/ImageComponent'
import { getStorageItem, setStorageItem } from 'dpn-common/services/SharedServices'
import { LocalImages } from 'utils/LocalImages'
import { GROUP_TYPES, USER_TYPES } from 'dpn-common/utils/AppConstants'
import NavigatorService from 'services/common/NavigatorService'
import { connect } from 'react-redux'
import { getFromDict } from 'dpn-common/utils/HelperFunctions'
import { getSchoolYearsFetch } from 'dpn-common/actions/SchoolYearActions'
import { lessonsByKeySelector } from 'dpn-common/reselects/LessonsReselects'
import { loadLessonsForGroupsFetch } from 'dpn-common/actions/LessonActions'
import { getGroupIdsForTeacherGroupLessons } from 'dpn-common/utils/GroupsDataUtils'
import { CloseIcon } from 'components/General/Icons'

const STORAGE_KEY = 'not_shown_again_tutorial'

class ViewTutorialsModalComponent extends React.PureComponent {
    constructor(props) {
        super(props)
        this.state = { isChecked: false, open: false }
    }

    show = async () => {
        if (!this.state.open) {
            let data = await this.getSettingsFromStorage(STORAGE_KEY)
            if (!data) {
                const key = `${STORAGE_KEY}_${this.props.userId}`
                data = await this.getSettingsFromStorage(key)
            }
            const route = NavigatorService.getCurrentRoute()
            if (!data && route.name !== 'TeacherLessonInfo' && route.name !== 'LoginExternal' && !this.isUnmounted) {
                this.setState({ open: true, isChecked: false })
                this.fetch()
            }
        }
    }

    getSettingsFromStorage = async (key) => {
        const data = await getStorageItem(key)
        return data && JSON.parse(data) || false
    }

    fetch = async () => {
        if (this.props.role === USER_TYPES.TEACHER) {
            this.props.schoolYears === undefined && await this.props.getSchoolYears(this.props.userId)
            const { unloadedGroupIds } = lessonsByKeySelector(this.props)
            unloadedGroupIds.length > 0 && await this.props.loadLessons(this.props.schoolYearId, unloadedGroupIds)
        }
    }

    componentWillUnmount() {
        this.isUnmounted = true
    }

    onCancelPress = () => {
        this.setState({ open: false })
        const key = `${STORAGE_KEY}_${this.props.userId}`
        this.state.isChecked && setStorageItem(key, JSON.stringify(true))
    } 

    onNotShownAgainPress = () => {
        this.setState({ isChecked: !this.state.isChecked })
    }

    onOkPress = () => {
        this.setState({ open: false })
        const key = `${STORAGE_KEY}_${this.props.userId}`
        this.state.isChecked && setStorageItem(key, JSON.stringify(true))
        if (this.props.onOkPress) {
            this.props.onOkPress()
        }
        else {
            switch (this.props.role) {
            case USER_TYPES.TEACHER:
                NavigatorService.navigateAndReset('TeacherVideoTutorials')
                break
            case USER_TYPES.STUDENT:
                NavigatorService.navigateAndReset('StudentVideoTutorials')
                break
            }
        }
    }

    onStartTour = () => {
        const { lessonId, lessonSchoolYearId, lessonGroupId, studentGroupId } = this.props
        const key = `${STORAGE_KEY}_${this.props.userId}`
        this.state.isChecked && setStorageItem(key, JSON.stringify(true))
        this.setState({ open: false })
        if (this.state.isChecked) {
            NavigatorService.navigateAndReset('TeacherPianoAdventures')
            return
        }
        if (lessonId && lessonSchoolYearId && lessonGroupId && studentGroupId) {
            NavigatorService.navigateAndReset('TeacherLessonInfo', {
                lessonId,
                lessonSchoolYearId,
                lessonGroupId,
                studentGroupId,
                run: true,
            })
        }
    }
    
    render() {
        return (
            <Dialog onEscapeKeyDown={this.onCancelPress} open={this.state.open} fullWidth maxWidth="sm">
                <AppBar position="static" variant="outlined">
                    <Toolbar className="flex w-full justify-between ">
                        <Typography className='flex grow-1 mx-auto text-center sm:text-20 text-16' variant="h6" color="inherit">
                            {texti18n(Messages.WELCOME_TO)}&nbsp;<span className="font-bold">{texti18n(Messages.SITE_NAME)}</span>
                        </Typography>
                        <div className='flex'>
                            <CloseIcon onClick={this.onCancelPress} style={{ cursor: 'pointer' }} />
                        </div>
                    </Toolbar>
                </AppBar>

                <DialogContent className="mt-5" classes={{ root: 'p-0' }}>
                    <div className="flex justify-center sm:justify-start flex-wrap ml-8 mr-8">
                        { 
                            this.props.role === USER_TYPES.TEACHER ?
                                <a onClick={this.onStartTour} style={{ cursor: 'pointer' }}>
                                    <ImageComponent src={LocalImages.INTERACTIVE_TOUR} />
                                </a>
                                :
                                <a onClick={this.onOkPress} style={{ cursor: 'pointer' }}>
                                    <ImageComponent src={LocalImages.VIEW_TUTORIALS} />
                                </a>
                        }   
                    </div>
                    {
                        this.props.role === USER_TYPES.STUDENT &&
                    
                        <FormControlLabel
                            className="ml-8 mr-8"
                            control={<Checkbox 
                                name="checkedC" onClick={this.onNotShownAgainPress} checked={this.state.isChecked} />
                            } label={texti18n(Messages.DO_NOT_SHOW_AGAIN)} 
                        />
                    }
                    {
                        this.props.role === USER_TYPES.TEACHER &&
                    <>
                        <div className="flex justify-between ml-8 mr-8">
                            <FormControlLabel
                                control={<Checkbox 
                                    name="checkedC" onClick={this.onNotShownAgainPress} checked={this.state.isChecked} /> 
                                } label={texti18n(Messages.SKIP_TUTORIAL)} 
                            />
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={this.onStartTour}
                                style={{ textTransform: 'none' }}
                            >
                                {texti18n(Messages.NEXT)}
                            </Button>
                        </div>

                        <div className="flex flex-col items-center">
                            <Typography className='text-center' variant="subtitle1" color="inherit">
                                {texti18n(Messages.LEARN_MORE_WITH_ADDITIONAL)} <span className="font-bold">{texti18n(Messages.VIDEO_TUTORIALS)}</span>
                            </Typography>
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={this.onOkPress}
                                style={{ textTransform: 'none' }}
                            >
                                {texti18n(Messages.VIEW_VIDEOS)}
                            </Button>
                        </div>
                    </>
                    }
                </DialogContent>

                <DialogActions>

                    {
                        this.props.role === USER_TYPES.STUDENT &&
                        <>
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={this.onCancelPress}
                            >
                                {texti18n(Messages.CANCEL)}
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={this.onOkPress}
                            >
                                {texti18n(Messages.OK)}
                            </Button>
                            </>
                    }
                </DialogActions>

            </Dialog>
        )
    }
}

const mapStateToProps = state => {
    const userId = state.base.auth.userData.id
    const schoolYears = getFromDict(state.schoolYear.schoolYears, 0)
    const activeSchoolYear = (schoolYears || []).find(x => x.isActive === true)
    const userGroups = state.group.userGroups.filter(x => x.groupType === GROUP_TYPES.TEACHER_SINGLE_STUDENT && x.isActive === true && x.users.every(user => user.isActive === true))
    const teacherUserGroup = userGroups.reduce((prev, curr) => prev.id < curr.id ? prev : curr, {})
    const studentGroupId = teacherUserGroup && teacherUserGroup.id
    const lessonsByGroupId = getFromDict(state.lesson.lessons, studentGroupId)
    const lessonsBySchoolYearId = getFromDict(lessonsByGroupId, 0)
    const lessonId = lessonsBySchoolYearId && lessonsBySchoolYearId.find(x => x.isActive === true && x.schoolYearId === activeSchoolYear.id)?.id
    const keyIds = getGroupIdsForTeacherGroupLessons(state.group.userGroups, studentGroupId, studentGroupId)
    return {
        userId,
        keyIds,
        lessonId,
        lessonSchoolYearId: activeSchoolYear?.id,
        lessonGroupId: studentGroupId,
        studentGroupId,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getSchoolYears: teacherId => getSchoolYearsFetch(teacherId, true, dispatch),
        loadLessons: (schoolYearId, groupIds) => loadLessonsForGroupsFetch(schoolYearId, groupIds, dispatch),
    }
}


export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(ViewTutorialsModalComponent)