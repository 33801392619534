/* eslint-disable no-unused-vars */

export const ENV_TYPES = Object.freeze({
    Development: 'development',
    Staging: 'staging',
    Production: 'production',
})

const DevConfigCrev = Object.freeze({
    staticFilesPath: {
        identityPath: 'https://csbd4551637646bx460exaf0.blob.core.windows.net/images',
        baseEndPointPath: 'https://csbd4551637646bx460exaf0.blob.core.windows.net/dpnstaticdev'
    },
    backendUrls: {
        identityService: 'https://192.168.0.28:5001',
        baseEndPointUrl: 'https://192.168.0.28:8085',
    },
    ismUrl: 'https://ism-frontend.azurewebsites.net',
    paAdultBaseUrl: 'https://adult.mypiano.app',
    environment: ENV_TYPES.Development,
    enableNotifications: false,
    topicSuffix: 'Crev',
    enableSentry: false,
    sentryUrl: 'https://3f8ca7aedf7e47789148d5b4851eed57@o310306.ingest.sentry.io/5668793',
    enablePianoAdv: true,
    contactEmail: ['badboy.solutions.ns@gmail.com'],
    wyswygUrl: 'https://mypadpn.azurewebsites.net/tinymce/js/tinymce/tinymce.min.js',
    enableSocialsAuth: true,
    ismEnabled: false,
    fbAppId: '746793535671413',
    externalLogin: {
        'paplus': 'https://adult.mypiano.app/'
    }
})

const DevConfigZoltan = Object.freeze({
    staticFilesPath: {
        identityPath: 'https://csbd4551637646bx460exaf0.blob.core.windows.net/images',
        baseEndPointPath: 'https://csbd4551637646bx460exaf0.blob.core.windows.net/dpnstaticdev'
    },
    backendUrls: {
        identityService: 'http://192.168.0.11:5000',
        baseEndPointUrl: 'http://192.168.0.11:8080',
    },
    ismUrl: 'https://ism-frontend.azurewebsites.net',
    paAdultBaseUrl: 'https://adult.mypiano.app',
    environment: ENV_TYPES.Development,
    enableNotifications: false,
    topicSuffix: 'Zoltan',
    enableSentry: false,
    enablePianoAdv: true,
    contactEmail: ['badboy.solutions.ns@gmail.com'],
    wyswygUrl: 'https://mypadpn.azurewebsites.net/tinymce/js/tinymce/tinymce.min.js',
    enableSocialsAuth: true,
    ismEnabled: false,
    fbAppId: undefined,
    externalLogin: {
        'paplus': 'https://adult.mypiano.app/'
    }
})

const DevConfigMilos = Object.freeze({
    staticFilesPath: {
        identityPath: 'https://csbd4551637646bx460exaf0.blob.core.windows.net/images',
        baseEndPointPath: 'https://csbd4551637646bx460exaf0.blob.core.windows.net/dpnstaticdev'
    },
    backendUrls: {
        identityService: 'http://192.168.0.15:5000',
        baseEndPointUrl: 'http://192.168.0.15:8080',
    },
    ismUrl: 'https://ism-frontend.azurewebsites.net',
    paAdultBaseUrl: 'https://adult.mypiano.app',
    environment: ENV_TYPES.Development,
    enableNotifications: true,
    topicSuffix: 'Milos',
    enableSentry: false,
    enablePianoAdv: true,
    contactEmail: ['badboy.solutions.ns@gmail.com'],
    wyswygUrl: 'https://mypadpn.azurewebsites.net/tinymce/js/tinymce/tinymce.min.js',
    enableSocialsAuth: true,
    ismEnabled: false,
    fbAppId: undefined,
    externalLogin: {
        'paplus': 'https://adult.mypiano.app/'
    }
})

const DevConfigNikola = Object.freeze({
    staticFilesPath: {
        identityPath: 'https://csbd4551637646bx460exaf0.blob.core.windows.net/images',
        baseEndPointPath: 'https://csbd4551637646bx460exaf0.blob.core.windows.net/dpnstaticdev'
    },
    backendUrls: {
        identityService: 'http://192.168.1.4:5000',
        baseEndPointUrl: 'http://192.168.1.4:8080',
    },
    ismUrl: 'https://ism-frontend.azurewebsites.net',
    paAdultBaseUrl: 'https://adult.mypiano.app',
    environment: ENV_TYPES.Development,
    enableNotifications: true,
    topicSuffix: 'Nikola',
    enableSentry: false,
    enablePianoAdv: true,
    contactEmail: ['badboy.solutions.ns@gmail.com'],
    wyswygUrl: 'https://mypadpn.azurewebsites.net/tinymce/js/tinymce/tinymce.min.js',
    enableSocialsAuth: true,
    ismEnabled: false,
    fbAppId: undefined,
    externalLogin: {
        'paplus': 'https://adult.mypiano.app/'
    }
})

const ProductionConfig = Object.freeze({
    staticFilesPath: 'https://digitalpianonotebook.azurewebsites.net',
    baseEndPointUrl: 'https://digitalpianonotebook.azurewebsites.net',
    environment: ENV_TYPES.Production,
    enableNotifications: true,
    topicSuffix: '',
    enableSentry: true,
    sentryUrl: 'https://92837acceb2142dbb3253234418fdb0c@sentry.io/1775795',
    enablePianoAdv: false,
    fetchTimeout: 60000,
})

const MyPaStagingConfig = Object.freeze({
    staticFilesPath: {
        identityPath: 'https://mypastagingstorage.blob.core.windows.net/identitystatic',
        baseEndPointPath: 'https://mypastagingstorage.blob.core.windows.net/dpnstatic'
    },
    backendUrls: {
        identityService: 'https://mypaidentitystaging.azurewebsites.net',
        baseEndPointUrl: 'https://mypadpnstaging.azurewebsites.net',
    },
    ismUrl: 'https://ism-staging-frontend.azurewebsites.net',
    paAdultBaseUrl: 'https://adult.mypiano.app/',
    environment: ENV_TYPES.Staging,
    enableNotifications: true,
    topicSuffix: 'Staging',
    enableSentry: true,
    sentryUrl: 'https://92837acceb2142dbb3253234418fdb0c@sentry.io/1775795',
    enablePianoAdv: true,
    fetchTimeout: 60000,
    contactEmail: ['badboy.solutions.ns@gmail.com', 'smicsasa@rogers.com'],
    wyswygUrl: 'https://mypadpnstaging.azurewebsites.net/tinymce/js/tinymce/tinymce.min.js',
    enableSocialsAuth: true,
    registrationCodeEnabled: true,
    ismEnabled: true,
    externalLogin: {
        'paplus': 'https://adult.mypiano.app/'
    }
})

const MyPaStagingWebConfig = Object.freeze({
    ...MyPaStagingConfig,
    sentryUrl: 'https://3f8ca7aedf7e47789148d5b4851eed57@o310306.ingest.sentry.io/5668793',
    enableNotifications: false,
    hotjarEnabled: true,
    hotjarSiteID: 2771560,
    GoogleAnalyticsEnabled: true,
    GoogleAnalyticsSiteID: 'G-Y3C183TFSP',
    AmplitudeAnalyticsEnabled: true,
    AmplitudeAnalyticsApiKey: '96a7041ed86c3f094f0e18c997c80108' //TODO: change api key
})

const MyPaProductionConfig = Object.freeze({
    staticFilesPath: {
        identityPath: 'https://mypastorage.azureedge.net/identitystatic',
        baseEndPointPath: 'https://mypastorage.azureedge.net/dpnstatic'
    },
    backendUrls: {
        identityService: 'https://mypaidentity.azurewebsites.net',
        baseEndPointUrl: 'https://mypadpn.azurewebsites.net',
    },
    ismUrl: 'https://ism-frontend.azurewebsites.net',
    paAdultBaseUrl: 'https://adult.mypiano.app',
    environment: ENV_TYPES.Production,
    enableNotifications: true,
    topicSuffix: '',
    enableSentry: true,
    sentryUrl: 'https://b57c25731ff24e5d8240e13877546aeb@o310306.ingest.sentry.io/5200031',
    enablePianoAdv: true,
    fetchTimeout: 60000,
    contactEmail: ['badboy.solutions.ns@gmail.com', 'smicsasa@rogers.com'],
    wyswygUrl: 'https://mypadpn.azurewebsites.net/tinymce/js/tinymce/tinymce.min.js',
    registrationCodeEnabled: true,
    enableSocialsAuth: true,
    ismEnabled: true,
    externalLogin: {
        'paplus': 'https://adult.mypiano.app/'
    }
})

const MyPaProductionWebConfig = Object.freeze({
    ...MyPaProductionConfig,
    sentryUrl: 'https://3f8ca7aedf7e47789148d5b4851eed57@o310306.ingest.sentry.io/5668793',
    enableNotifications: false,
    hotjarEnabled: true,
    hotjarSiteID: 2770410,
    GoogleAnalyticsEnabled: true,
    GoogleAnalyticsSiteID: 'G-VP25FDX79R', // prod
    AmplitudeAnalyticsEnabled: true,
    AmplitudeAnalyticsApiKey: '96a7041ed86c3f094f0e18c997c80108' //TODO: change api key
})

const AppConfig = MyPaStagingWebConfig

export default AppConfig
