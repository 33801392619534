import React from 'react'
import { AppBar, Hidden, Toolbar } from '@material-ui/core'
import { makeStyles, ThemeProvider } from '@material-ui/styles'
import { FuseShortcuts } from '@fuse'
import NavbarMobileToggleButton from 'app/fuse-layouts/shared-components/NavbarMobileToggleButton'
import UserMenu from 'app/fuse-layouts/shared-components/UserMenu'
import { useSelector } from 'react-redux'
import GoBackComponent from 'app/fuse-layouts/shared-components/GoBackComponent'
import { LocalImages } from 'utils/LocalImages'

const useStyles = makeStyles(theme => ({
    separator: {
        width: 1,
        height: 64,
        backgroundColor: theme.palette.divider
    },
    menuLogoIcon: {
        width: 36,
        height: 36,
    }
}))

function ToolbarLayout1(props) {
    const config = useSelector(({ fuse }) => fuse.settings.current.layout.config)
    const toolbarTheme = useSelector(({ fuse }) => fuse.settings.toolbarTheme)

    const classes = useStyles(props)
    return (
        <ThemeProvider theme={toolbarTheme}>
            <AppBar id="fuse-toolbar" className="flex relative z-10 max-h-63" elevation={0} color="default">
                <Toolbar className="p-0">

                    {config.navbar.display && config.navbar.position === 'left' && (
                        <>
                            <Hidden mdUp>
                                <NavbarMobileToggleButton className="w-64 h-64 p-0">
                                    <img className={classes.menuLogoIcon} src={LocalImages.PIANO_ADV} alt="logo"/>
                                </NavbarMobileToggleButton>
                                <div className={classes.separator}/>
                            </Hidden>
                            <GoBackComponent />
                        </>
                    )}

                    <div className="flex flex-1">
                        <Hidden smDown>
                            <FuseShortcuts className="px-16"/>
                        </Hidden>
                    </div>

                    <div className="flex">

                        <UserMenu/>

                        <div className={classes.separator}/>

                        {/* <FuseSearch/> */}

                        <Hidden mdUp>

                            <div className={classes.separator}/>

                        </Hidden>

                        <div className={classes.separator}/>

                        {/* <QuickPanelToggleButton/> */}
                    </div>

                    {config.navbar.display && config.navbar.position === 'right' && (
                        <Hidden mdUp>
                            <NavbarMobileToggleButton/>
                        </Hidden>
                    )}
                </Toolbar>
            </AppBar>
        </ThemeProvider>
    )
}

export default ToolbarLayout1
